<template>
  <section class="grid-columns-2">
    <div class="bg-full contact">
    </div>
    <div class="container-padding mb-5">
      <h1 class="color-secondary">Contacto</h1>
      <form class="form">
        <label>
          Nombre
          <input type="text" placeholder="Ingresa tu Nombre"/>
        </label>
        <label>
          Correo electrónico
          <input type="text" placeholder="Ingresa tu correo electrónico"/>
        </label>
        <label>
          Razón
          <select name="razon">
            <option value="opcion1">Opción 1</option>
            <option value="opcion2">Opción 2</option>
            <option value="opcion3">Opción 3</option>
          </select>
        </label>
        <label>
          Sucursal
          <select name="razon">
            <option value="opcion1">Opción 1</option>
            <option value="opcion2">Opción 2</option>
            <option value="opcion3">Opción 3</option>
          </select>
        </label>
        <label>
          Comentarios
          <textarea placeholder="Comentarios"></textarea>
        </label>
        <input type="submit" value="Enviar Mensaje">
      </form>
      <div>
        <h3>¡Queremos saber de ti!</h3>
        <p>Escríbenos y nos pondremos en contacto contigo.</p>
      </div>
      <div class="flex">
          <div>
            <h4 class="color-secondary">Correo elctrónico</h4>
            <a href="mailto:atencionaclientes@elcalifa.com">
              atencionaclientes@elcalifa.com
            </a>
          </div>
          <div>
            <h4 class="color-secondary">Sigenos</h4>
            <div class="flex">
              <div>
                <a>Twitter</a>
              </div>
              <div>
                <a>Facebook</a>
              </div>
              <div>
                <a>Instagram</a>
              </div>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<style>
.bg-full{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100% !important;
}
.bg-full.contact{
   background-image: url("../../src/assets/califa-contact.jpg");
}
</style>
